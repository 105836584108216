import BigButtonGroup from '~/components/BigButtonGroup.vue';
import Layout3 from '~/components/layouts/Layout3.vue';
export default defineComponent({
  name: 'BusinessSurveyPeople',
  components: {
    Layout3: Layout3,
    BigButtonGroup: BigButtonGroup
  },
  provide: function provide() {
    return {
      headingBottomPaddingClass: 'pb-8'
    };
  },
  computed: {
    values: function values() {
      return [{
        text: '1',
        value: 'Self-employed'
      }, {
        text: '2-10',
        value: '1'
      }, {
        text: '11-50',
        value: '2'
      }, {
        text: '51-200',
        value: '51-200'
      }, {
        text: '201+',
        value: '3'
      }];
    }
  }
});