import BigButtonGroup from '~/components/BigButtonGroup.vue';
import Layout3 from '~/components/layouts/Layout3.vue';
export default defineComponent({
  name: 'BusinessSurveyPeople',
  components: {
    Layout3: Layout3,
    BigButtonGroup: BigButtonGroup
  },
  provide: function provide() {
    return {
      headingBottomPaddingClass: 'pb-8'
    };
  },
  computed: {
    values: function values() {
      return [{
        text: this.$t('business.signup.survey.usage.options.seldom'),
        value: 'Seldom'
      }, {
        text: this.$t('business.signup.survey.usage.options.often'),
        value: 'Often'
      }, {
        text: this.$t('business.signup.survey.usage.options.invited'),
        value: 'Invited'
      }];
    }
  }
});