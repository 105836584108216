import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import Layout3 from '@/components/layouts/Layout3.vue';
import useValidation from '@/composables/useValidation';
export default defineComponent({
  name: 'SetPassword',
  components: {
    Layout3: Layout3
  },
  props: {
    companyName: String,
    companyPhone: String,
    companyGtcAccepted: Boolean,
    loading: Boolean,
    userFirstName: String,
    userLastName: String,
    showBackButton: Boolean,
    showPersonalDetails: Boolean
  },
  data: function data() {
    return {
      company: {
        name: this.companyName,
        phone: this.companyPhone,
        gtcAccepted: this.companyGtcAccepted
      },
      user: {
        firstName: this.userFirstName || '',
        lastName: this.userLastName || ''
      },
      companyNameCompliant: false,
      firstNameCompliant: false,
      lastNameCompliant: false,
      phoneCompliant: false
    };
  },
  setup: function setup() {
    var _useValidation = useValidation(),
      isPhoneNumber = _useValidation.isPhoneNumber;
    return {
      isPhoneNumber: isPhoneNumber
    };
  },
  computed: {
    compliant: function compliant() {
      if (!this.showPersonalDetails) {
        return this.companyNameCompliant && this.company.gtcAccepted && this.phoneCompliant;
      }
      return this.firstNameCompliant && this.lastNameCompliant && this.companyNameCompliant && this.phoneCompliant && this.company.gtcAccepted;
    },
    title: function title() {
      if (this.$route.name === 'business-signup-steps') return this.$t('business.signup.step.2.title');
      if (this.$route.name === 'business-register') return this.$t('business.register.title');
      return '';
    },
    subtitle: function subtitle() {
      if (this.$route.name === 'business-signup-steps') return this.$t('business.signup.step.2.text');
      if (this.$route.name === 'business-register') return this.$t('business.signup.subtitle');
      return '';
    }
  },
  created: function created() {
    var storedName = localStorage.getItem('signup-name');
    if (storedName) {
      var parsedName = JSON.parse(storedName);
      this.user.firstName = parsedName.firstName;
      this.user.lastName = parsedName.lastName;
      this.firstNameCompliant = true;
      this.lastNameCompliant = true;
    }
    this.companyNameCompliant = !!this.company.name;
    this.firstNameCompliant = !!this.user.firstName;
    this.lastNameCompliant = !!this.user.lastName;
    this.phoneCompliant = !!this.company.phone;
  },
  methods: {
    storeName: function storeName() {
      localStorage.setItem('signup-name', JSON.stringify({
        firstName: this.user.firstName,
        lastName: this.user.lastName
      }));
    }
  }
});